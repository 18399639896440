<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0 bg-none"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Name</th>
                  <th>meta Title</th>
                  <th>meta Description</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, index) in categories.data"
                  :key="index"
                  v-if="categories.data.length"
                >
                  <td class="align-middle text-center">
                    {{ categories.meta.from + index }}
                  </td>
                  <td class="align-middle">
                    <b>{{ category.name }}</b>
                  </td>
                  <td class="align-middle">
                    <b>{{ category.meta_title }}</b>
                  </td>
                  <td class="align-middle">
                    <b>{{ category.meta_description }}</b>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        category.status == 'Active'
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ category.status }}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <button
                      v-if="permission.edit"
                      class="btn btn-primary btn-sm"
                      @click="edit(category.id)"
                      style="margin-right: 5px"
                    >
                      <feather-icon icon="EditIcon" size="16" />
                    </button>
                    <button
                      v-if="permission.delete"
                      class="btn btn-danger btn-sm"
                      @click="Delete(category.id)"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing
                  {{ categories.meta.from ? categories.meta.from : 0 }} to
                  {{ categories.meta.to ? categories.meta.to : 0 }} of
                  {{ categories.meta.total ? categories.meta.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="categories"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="(form.id == '' ? 'Create' : 'Update') + ' News Category'"
        >
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Name" label-for="name_en">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Name English"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Meta Title" label-for="meta_title">
                      <validation-provider
                        #default="{ errors }"
                        name="meta_title"
                        rules="required"
                      >
                        <b-form-input
                          id="meta_title"
                          v-model="form.meta_title"
                          :state="errors.length > 0 ? false : null"
                          name="meta_title"
                          placeholder="Enter Your Meta Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Meta Description"
                      label-for="meta_description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="meta_description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="meta_description"
                          v-model="form.meta_description"
                          :state="errors.length > 0 ? false : null"
                          name="meta_description"
                          placeholder="Enter Your Meta Description"
                          rows="4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  Status
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import _ from "lodash";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      form: {
        id: "",
        name: "",
        meta_title: "",
        meta_description: "",
        status: true,
      },
      categories: [],
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/news/category${queryParams}`)
        .then((res) => {
          this.categories = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Close() {
      this.form.id = "";
      this.form.name = "";
      this.form.meta_title = "";
      this.form.meta_description = "";
      this.form.status = true;
    },

    async edit(id) {
      const category = await this.callApi("get", "/news/category/" + id);
      if (category.status == 200) {
        this.form.id = category.data.category.id;
        this.form.name = category.data.category.name;
        this.form.meta_title = category.data.category.meta_title;
        this.form.meta_description = category.data.category.meta_description;
        this.form.status = category.data.category.status == true ? true : false;
      }
    },

    Create() {
      axios
        .post("/news/category", this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
          }
        });
    },
    Update() {
      axios
        .put("/news/category/" + this.form.id, this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
          }
        });
    },
    async Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/news/category/" + id)
            .then((response) => {
              this.s(response.data.message);
              this.getResults();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
        }
      });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          if (this.form.id == "") {
            this.Create();
          } else {
            this.Update();
          }
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "news.category.access") {
          this.permission.access = true;
        }

        if (permission.slug == "news.category.create") {
          this.permission.create = true;
        }

        if (permission.slug == "news.category.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "news.category.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
